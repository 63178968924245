<template>
  <div class="login">
    <div
      class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
    >
      <div
        class="max-w-md w-full space-y-8 shadow-2xl bg-gray-800 p-6 rounded-lg"
      >
        <div>
          <span class="text-xl pl-2"
            ><img src="../assets/logo.png" alt="" />
          </span>
          <h2 class="mt-6 text-center text-3xl font-extrabold text-white font-sans">
            Inicia sesión con tu cuenta
          </h2>
        </div>

        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-xl -space-y-px font-sans">
          <div>
            <label for="usuario" class="sr-only">Usuario</label>
            <input
              @keypress.enter="login"
              v-model="usuario"
              id="usuario"
              name="usuario"
              type="text"
              autocomplete="text"
              required=""
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="usuario"
            />
          </div>
          <div>
            <label for="contraseña" class="sr-only">Contraseña</label>
            <input
              @keypress.enter="login"
              v-model="contraseña"
              id="contraseña"
              name="contraseña"
              type="password"
              autocomplete="current-password"
              required=""
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Contraseña"
            />
          </div>
        </div>
        <div v-if="error">
          <label class="ml-2 block text-sm text-red-600">
            Datos Incorrectos
          </label>
        </div>
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input
              id="remember_me"
              name="remember_me"
              type="checkbox"
              class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <label for="remember_me" class="ml-2 block text-sm text-white">
              Recordarme
            </label>
          </div>
        </div>
        <div>
          <button
            @click="login"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            </span>
            Iniciar sesión
          </button>
        </div>
      </div>
    </div>
    <div class="VersionApp">{{ "Ver " + VersionApp }}</div>
  </div>
</template>

<script>
import auth from "@/logic/auth";
import { version } from "../../package.json"
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  components: {},
  data() {
    return {
      usuario: "",
      contraseña: "",
      credenciales: {},
      datos: "hidden",
      nombre: "",
      error: false,
    };
  },
  methods: {
    async login(state) {
      try {
        await auth
          .login(this.usuario, this.contraseña)
          .then((result) => {
            const user = {
              usuario: result.data.nombre,
              token: result.data.token,
              id: result.data.numcodigo,
              esJefe: result.data.jefeGCasos,
              loginUsuario: result.data.usuario,
              parametrosPase: result.data.parametrosPaseJson
            };
            auth.setUserLogged(user);
            state.token = user.token;
            state.esJefe = user.esJefe;
            state.display = "block";
            state.usuario = user.usuario;
            this.setPermisosUsuario(result.data.permisosPortalGestion)
            this.setInfoAgentesWolkvox(result.data.infoAgentesWolkvox[0])
            this.setMotivosLLamadasCobranza(result.data.motivosLlamadasCobranza)
            this.loadNavbar(user);
            this.$router.push("/Inicio");
          })
          .catch((err) => {
            console.log(err);
            this.error = true;
          });
      } catch (error) {
       // console.log(error);
        this.error = true;
      }
    },
    ...mapMutations(["loadNavbar", "hiddenNavbar", "logueo", "closeModalCargando","setPermisosUsuario","setInfoAgentesWolkvox","setMotivosLLamadasCobranza"]),
  },
  computed: {
    ...mapState(["token", "display", "esJefe"]),
    VersionApp(){
      return version.trim()
    }
  },
  mounted() {
    this.closeModalCargando()
    this.hiddenNavbar();
  },
};
</script>

<style>
.VersionApp {
  color: rgba(31,41,55,0.3);
  position: absolute;
  display: block;
  bottom: 0px;
  width: 100vw;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 700;
}
</style>
